import React from 'react';
import Grid from '../components/Grid';
import TransformNav from '../components/TransformNav';

export default function FourOhFourPage() {
  return (
    <>
      <TransformNav />
      <Grid>
        <div className="container">
          <h1>404</h1>
          <p>Sorry deze pagina bestaat niet.</p>
        </div>
      </Grid>
    </>
  );
}
